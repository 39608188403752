/* eslint-disable react/no-danger */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import HeadNext from "next/head";
import getConfig from "next/config";
import { withRouter } from "next/router";
import Logger from '@lmig/dotcom-aspect-helpers/logger';
import { ContextPage } from '@lmig/dotcom-aspect-components/ContextPage';
import { ContextApp } from '@lmig/dotcom-aspect-components/ContextApp';
import { EntityContext } from '@lmig/dotcom-aspect-comparion-components/EntityContext';
import urlHelper from '@lmig/dotcom-aspect-helpers/urlHelper';

const logger = new Logger('client:components:Head');

const {
  publicRuntimeConfig: {
    environmentalizedDomains: { IMAGE },
    defaultOpenGraphImage,
    version
  },
} = getConfig();

// eslint-disable-next-line complexity
const Head = ({
  children,
  router,
  ...overwrites
}) => {
  const entityContext = useContext(EntityContext);
  const pageProviderValues = useContext(ContextPage);
  const appContext = useContext(ContextApp);

  if (!pageProviderValues) {
    throw new Error('<Head> must be within a <PageContextProvider>');
  }

  if (!appContext) {
    throw new Error('<Head> must be within a <ContextAppProvider>');
  }

  if (!entityContext) {
    throw new Error('<Head> must be within a <EntityContextProvider>');
  }

  const { host } = appContext;
  const { isNoIndex } = entityContext;

  const {
    content: { meta = {} } = {},
    page: { excludeSitemap }
  } = pageProviderValues;

  const mergedPropsAndMeta = { ...meta, ...overwrites };

  const {
    title = null,
    description = null,
    openGraphType = "website",
    openGraphImage = defaultOpenGraphImage,
    schema,
    canonical,
  } = mergedPropsAndMeta;

  const { origin, pathname } = urlHelper(router?.query?.reqPath || '', host);
  const url = `${origin}${pathname}`;
  const pageName = canonical ?? url;
  const formattedPageName = pageName.replace(/%20/g, '-');
  let stringifiedSchema = '';

  try {
    stringifiedSchema = JSON.stringify(schema);
  } catch (e) {
    logger.error(`Something went wrong stringifying schema: ${e.message}`);
  }

  const hasSchema = !!schema && stringifiedSchema !== '';

  const completeImage = `${origin}${openGraphImage}`;

  return (
    <HeadNext>
      <meta charSet="utf-8" />
      {title && (
        <>
          <title key="site-title">{title}</title>
          <meta property="og:title" content={title} />
        </>
      )}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

      {description && (
        <>
          <meta
            key="site-meta-description"
            name="description"
            content={description}
          />
          <meta property="og:description" content={description} />
        </>
      )}
      <meta property="og:type" content={openGraphType} />
      <meta property="og:image" content={completeImage} />
      <meta property="og:url" content={url} />
      <link rel="canonical" href={formattedPageName} />
      <link rel="dns-prefetch" href={IMAGE} />
      <meta name="version" content={version} />
      {(excludeSitemap || isNoIndex) && <meta name="robots" content="noindex" />}
      {hasSchema && (
        <script
          data-testid="page-schema"
          type="application/ld+json"
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: stringifiedSchema,
          }}
        />
      )}
      {children}
    </HeadNext>
  );
};

Head.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    openGraphType: PropTypes.string,
    openGraphImage: PropTypes.string,
    canonical: PropTypes.string,
  }),
  children: PropTypes.element,
  router: PropTypes.shape({
    asPath: PropTypes.string,
  })
};

export default withRouter(Head);
