/* eslint-disable complexity */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { EntityContext } from '@lmig/dotcom-aspect-comparion-components/EntityContext';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';

const CapSessionCard = ({
  isCapLanding = false,
  cap: capProp
}) => {
  const { entities: { cap: capContext } } = useContext(EntityContext);
  const [cap, setCap] = useState(capProp);

  useEffect(() => {
    if (!isCapLanding) {
      setCap(capContext);

      if (capContext.partnerId) {
        if (window?.utag_data) {
          window.utag_data.partnerId = capContext.partnerId;
        }

        if (window?.galileoData) {
          window.galileoData.partnerId = capContext.partnerId;
        }
      }
    }
  }, [setCap, isCapLanding, capContext]);

  if (!cap?.active) {
    return null;
  }

  const { image: capImage, name, greeting } = cap;
  const capImageSrc = capImage?.src || '/images/comparion_logo_teal.svg';
  const capImageAlt = capImage?.alt || 'Comparion Insurance Logo';
  const capGreeting = greeting || `${name} has partnered with the Comparion Insurance Agency to offer you options for your personal insurance.`;

  return (
    <>
      <div id="capSessionCard" data-testid="capSessionCard">
        <div className={`cardWrapper ${!capImage ? "noCapImage" : ''} ${isCapLanding ? "isCapLanding" : ''}`}>
          {(capImage || !isCapLanding) &&
            <div className="logos">
              {capImage &&
                <div className="capLogo">
                  <Image imageDomain={capImage ? "IMAGE" : "COMPARION"} deferLoad={false}>
                    <img src={`${capImageSrc}`} alt={capImageAlt} width="9.375rem" height="4.6875rem" />
                  </Image>
                </div>
              }
            </div>
          }
          <div className="capContent">
            <div className="headline">{capGreeting}</div>
          </div>
        </div>
      </div>
      <style global jsx>{`
        @import './global-styles/tokens';
        #capSessionCard {
          width: 100%;
          max-width: 24rem;
          padding: 0 0.5rem;
          margin: 2rem auto;

          @media screen and (min-width: $lm-breakpoint-md-min) {
            max-width: 72rem;
          }
          @media screen and (min-width: $lm-breakpoint-xl-min) {
            padding: 0;
          }
          .cardWrapper {
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
            width: 100%;
            display: flex;
            justify-content: space-around;
            border-radius: 0.375rem;
            flex-flow: column;
            overflow: hidden;

            &.isCapLanding {
              min-height: 10rem;
            }
            &.noCapImage{
              background: #F2FCFC;
            }
            @media screen and (min-width: $lm-breakpoint-md-min) {
              flex-flow: row;
              background: #F2FCFC;
            }
          }
          .logos {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            width: 100%;
            background: #FFF;
            padding: 1rem 0;

            @media screen and (min-width: $lm-breakpoint-md-min) {
              max-width: 20.5rem;
            }
          }
          .capLogo {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            max-width: 12.1875rem;
            
            img {
              width: 100%;
              max-width: 9.375rem;
              max-height: 4.6875rem;
            }
          }
          .capLogo + .agentContainer {
            margin-top: 0.5rem;

            @media screen and (min-width: $lm-breakpoint-md-min) {
              margin-top: 1rem;
            }
          }
          .agentContainer {
            display: flex;
            align-items: center;
            height: 100%;
            max-width: 20.5rem;
            margin: 0 1rem;

            img {
              min-height: 3.125rem;
              min-width: 3.125rem;
              border: 5px solid #E6E6E6;
            }
            .agentName {
              margin-left: 1rem;
            }
          }
          .capContent {
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 100%;
            background: #F2FCFC;
            color: #343741;
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            text-align: center;
            line-height: 1.375rem;

            @media screen and (min-width: $lm-breakpoint-md-min) {
              padding: 1rem 2rem;
              font-size: 1rem;
              line-height: 1.75em;
              text-align: left;
            }
            .headline {
              font-weight: 600;
            }
          }
        }
      `}</style>
    </>
  );
};

CapSessionCard.propTypes = {
  isCapLanding: PropTypes.bool,
};

export default CapSessionCard;