/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from 'prop-types';
import { Link } from '@lmig/lmds-react';
import RatingStars from './RatingStars';

const Rating = ({
  rating = 0,
  count = 0,
  type = 'agentCard',
  isLinked = false
}) => {
  const ratingProps = {
    className: type === "agentOfficeReviews" ? "rating" : "ratingInline"
  };

  return (
    <div {...ratingProps}>
      {type === "agentCard" && <>
        <span className="ratingNumber">{`${rating || 0} / 5`}</span>
        <span className="stars"><RatingStars rating={rating} size={16} starType="classic" /></span>
        <span className="count">
          {isLinked
            ? <Link href="#reviews">{`(${count} Reviews)`}</Link>
            : `(${count} Reviews)`
          }</span>
      </>}
      {type === "quoteCard" && <>
        <span className="starsQuote"><RatingStars rating={rating} size={16} starType="classic" /></span>
        <span className="ratingNumberQuote">{`\xa0${rating || 0}/5`}</span>
      </>}
      {type === "agentOfficeReviews" && <>
        <span className="starsLmds"><RatingStars rating={rating} size={16} starType="lmds" /></span>
        <span className="ratingNumberNonBold">{`${rating || 0} / 5 stars`}{count > 1 ? ` - ${count} Reviews` : ` - ${count} Review`}</span>
      </>}
      <style jsx>{`
      .rating{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0.5rem 0 1rem 0;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }
      }
      .ratingInline{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 0.5rem 0 1rem 0;
      }
      .stars{
        margin-top: 0.25rem;
      }
      .ratingNumber{
        font-weight: 500;
        margin-right: 0.5rem;
      }
      .count{
        margin-left: 0.5rem;
      }
      .ratingNumberNonBold{
        margin-left: 0.5rem;
      }
      .countNonBold{
        margin-left: 0.5rem;
      }
      .starsLmds{
        height: 1.2rem;
      }
      .starsQuote{
        margin-top: -0.4rem;
      }
      .ratingNumberQuote{
        font-weight: 500;
        margin-right: 0.5rem;
        margin-top: -0.5rem
      }
    `}</style>
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number,
  count: PropTypes.number,
  type: PropTypes.oneOf(["agentCard", "agentOfficeReviews", "quoteCard"]),
  isLinked: PropTypes.bool,
};

export default Rating;
